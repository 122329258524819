import React from 'react';
import { PopupButton } from 'react-calendly';
import './CalendlyForm.css';
import RightArrow from "../assets/icons/rightarrow.svg";

const CalendlyButtons = ({ buttonText, buttonStyle }) => {
    const calendlyUrl = 'https://calendly.com/adestoria/kennenlernen';

    return (
        <div className="hero-buttons">{/* Input Field */}
            <input
                type="text"
                placeholder="Wir sind dennoch für Sie erreichbar"
                className="input-field"
                disabled
            />
            <img src={RightArrow} alt='Arrow Icon' className='input-icon' />

            {/* Calendly Popup Button */}
            <PopupButton
                url={calendlyUrl}
                rootElement={document.getElementById('root')}
                text={buttonText}
                className="submit-button"
                style={buttonStyle}
            />
        </div>

    );
};

export default CalendlyButtons;
