import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './layouts/Header';
import MainContent from './components/MainContent';
import './App.css';

/*const App = () => {
  return (
    <div className="App" id="top">
      <Header />
      <MainContent />
    </div>
  );
};*/


function App() {
  return (

    <Router>
      {/*<Header /> */}
      <Routes>
        <Route path="/" element={<MainContent />} />
      </Routes>
    </Router>
  );
}

export default App;
