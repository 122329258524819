import React from 'react';
import './MainContent.css';
import CalendlyButtons from '../layouts/CalendlyForm';
import logo from '../assets/img/logo.webp';




const MainContent = () => {
    return (
        <main className="main-content">

            {/*Hero section*/}
            <section className="section-style hero-section">
                <div className='hero-section'>
                    <div className='logo-header'>
                        <img src={logo} alt='Adestoria Logo' className='logo' />
                    </div>


                    {/* Hero Section Heading */}
                    <span className="large-title">WIR SIND BALD FÜR<br />
                        Sie hier zu finden</span>
                    {/* Hero Section Input and Button */}
                    <CalendlyButtons
                        buttonText="HIER CLICKEN"
                        buttonStyle={{ backgroundColor: '#f67200', color: '#fff' }}

                    />
                </div>



            </section>
        </main>
    );
};

export default MainContent;
